<template>
  <div class="home">
    <HomeComponent msg="Benvenuto nel mio sito personale"/>
    <img src="../assets/CVSite.jpg" class="img-profile" alt="Responsive image">
  </div>
</template>

<style>
.img-profile {
   max-width: 60%;
   height: auto;
}
</style>

<script>
// @ is an alias to /src

//<img alt="Vue logo" src="../assets/logo.png">
import HomeComponent from '@/components/HomeComponent.vue'

export default {
  name: 'Home',
  components: {
    HomeComponent
  }
}
</script>
