<template>
<div>    
    <h3>{{msg}}</h3>
</div>

</template>

<script>
export default {
    name:'HomeComponent',
    props:{
        msg:String
    }
}
</script>